import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import theme from '../styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Line = styled.div`
  width: 60px;
  height: 4px;
  background-color: ${theme.colors.primary};
`;

const Title = styled.h4`
  font-size: ${theme.fontSizeLg * 0.85}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
`;

const Text = styled.p`
  font-size: ${theme.fontSizeLg * 0.9}px;
  color: ${theme.colors.grey3};
`

const StyledImage = styled(Img)`
  border-radius: 30px;
`;

const DesktopBlogPost = () => {
  const data = useStaticQuery(graphql`
    query {
      blogImage: file(relativePath: { eq: "blog.png" }) {
        childImageSharp {
          fixed(width: 349, height: 197) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <StyledImage fixed={data.blogImage.childImageSharp.fixed} />
      <Title>NA MÍDIA 01</Title>
      <Line />
      <Text>Lorem ipsum dolor sit amet, consectet dolor adipiscing elit.</Text>
    </Wrapper>
  );
};

export default DesktopBlogPost;
