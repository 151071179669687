import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';

import { CartIcon, ShieldIcon, ListIcon } from './icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 2rem;
`;

const Row = styled.div`
  display: flex;
  flex: 1 100%;
  flex-direction: row;
  justify-content: space-around;

  @media ${medias.phoneMax} {
    flex-direction: column;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27%;

  @media ${medias.phoneMax} {
    width: 100%;
  }
`;

const ItemTitle = styled.h2`
  color: ${theme.colors.primary};
  font-weight: bold;
  text-align: center;
  margin: ${props => (props.longTitle ? '12px 0 0 0' : '20px 0 20px 0')};
`;

const ItemText = styled.p`
  font-size: ${theme.fontSizeLg * 0.9}px;
  text-align: center;
  margin: ${props => (props.longTitle ? '12px 0 18px 0' : '18px 0')};
`;

const JoinInformation = () => {
  return (
    <Wrapper>
      <Row>
        <ItemContainer>
          <CartIcon />
          <ItemTitle longTitle>Taxa de Análise</ItemTitle>
          <ItemText longTitle>
            <b>R$280</b>, em caso de reprovação não será reembolsada.
          </ItemText>
        </ItemContainer>
        <ItemContainer>
          <ShieldIcon />
          <ItemTitle longTitle>Uso do selo</ItemTitle>
          <ItemText longTitle>
            Assinatura anual: de 12 x R$ 59,90 por <b>12 x R$ 39,90</b>.
          </ItemText>
        </ItemContainer>
        <ItemContainer>
          <ListIcon />
          <ItemTitle longTitle>Lista</ItemTitle>
          <ItemText longTitle>
            Após certificado, o fornecedor passa a estar na lista e recebe o
            certificado para impressão, selo e resultado da análise.
          </ItemText>
        </ItemContainer>
      </Row>
    </Wrapper>
  );
};

export default JoinInformation;
