import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import { TrashIcon } from './icons';
import {
  Button,
  MaterialInput,
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
} from './';
import FormControl from '@material-ui/core/FormControl';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`;

const ItemContainer = styled(RowContainer)`
  justify-content: flex-start;
  border-bottom: 2px solid ${theme.colors.grey1};
`;

const UserContainer = styled(RowContainer)`
  margin: 5px;
  ${({ border }) =>
    border &&
    `
      border-bottom: 2px solid ${theme.colors.grey1};
      padding-bottom: 10px;
    `};
`;

const RegionContainer = styled(RowContainer)`
  flex-grow: 1;
  flex-wrap: wrap;
`;

const HistoryRowContainer = styled(RowContainer)`
  justify-content: space-evenly;
  ${({ border }) => border && `border-bottom: 2px solid ${theme.colors.grey1};`}
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const BalanceContainer = styled.div`
  width: 100%;
  height: 65px;
  background-color: ${theme.colors.text_heading};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

const EventTitle = styled.h4`
  color: ${theme.colors.text_heading};
`;

const Text = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  margin: 0;
  ${({ region }) => region && `width: 30%;`}
`;

const BalanceText = styled.p`
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.white};
  margin: 0 5px 0 0;
`;

const HistoryTitle = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
  }

  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  margin: 10px 0 3px 0;
`;

const HistoryText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  margin: 0 0 10px 5px;
`;

const Title = styled.h2`
  color: ${theme.colors.grey3};
`;

const FormTitle = styled.h4`
  color: ${theme.colors.text_heading};
`;

const PointsText = styled(BalanceText)`
  font-weight: bold;
  color: ${theme.colors.primary};
  margin: 0 0 2px 0;
`;

const SpacedIcon = styled(Img)`
  margin-bottom: 0.5em;
  margin-right: 3em;
`;

const StyledButton = styled(Button)`
  width: ${({ intern }) => (intern ? '100%' : '90%')};
  background-color: ${({ opened }) =>
    opened ? theme.colors.white : theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: ${({ opened }) =>
    opened ? theme.colors.text_heading : theme.colors.white};
  margin-top: 1.5em;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: 90%;
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;

export const EventCard = () => {
  const [opened, setOpened] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "segment.png" }) {
        childImageSharp {
          fixed(width: 175, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const mockData = {
    services: [
      {
        id: 0,
        name: 'Nome do serviço',
      },
      {
        id: 1,
        name: 'Nome do serviço',
      },
      {
        id: 2,
        name: 'Nome do serviço',
      },
      {
        id: 3,
        name: 'Nome do serviço',
      },
    ],
    users: [
      {
        id: 0,
        name: 'Usuário 1',
        email: 'email@email.com',
      },
      {
        id: 1,
        name: 'Usuário 2',
        email: 'email@email.com',
      },
    ],
    region: [
      {
        id: 0,
        name: 'MG - Belo Horiozonte',
      },
      {
        id: 1,
        name: 'MG - Contagem',
      },
      {
        id: 2,
        name: 'SP - São Paulo',
      },
    ],
    history: [
      {
        id: 0,
        value: '+1',
        balance: '1',
        supplier: 'Rede Vestidas de Branco',
        date: '21/02/2019 13:25:56',
      },
      {
        id: 1,
        value: '+1',
        balance: '1',
        supplier: 'Rede Vestidas de Branco',
        date: '21/02/2019 13:25:56',
      },
      {
        id: 2,
        value: '+1',
        balance: '1',
        supplier: 'Rede Vestidas de Branco',
        date: '21/02/2019 13:25:56',
      },
    ],
  };
  return (
    <Wrapper>
      <RowContainer>
        {/* TODO: API data */}
        <EventTitle>Evento: 21/02/2019 19:00</EventTitle>
        <StyledTrashIcon />
      </RowContainer>
      <RowContainer>
        <Text>Nome do Espaço</Text>
        <Text> Local: MG - Belo Horizonte</Text>
      </RowContainer>
      {opened && (
        <>
          <Title>Serviços</Title>
          {mockData.services.map(({ id, name }) => (
            <ItemContainer key={id}>
              <SpacedIcon fixed={data.icon.childImageSharp.fixed} />
              <Text>{name}</Text>
            </ItemContainer>
          ))}
          <Title>Usuários</Title>
          {mockData.users.map(({ id, name, email }) => (
            <UserContainer key={id} border={id === mockData.users.length - 1}>
              <Text>{name}</Text>
              <Text>{email}</Text>
              <StyledTrashIcon />
            </UserContainer>
          ))}
          <FormContainer>
            <FormTitle>Adicionar usuários</FormTitle>
            <MaterialInput
              style={{ width: '100%' }}
              label="Nome"
              // value={name}
              // onChange={event => handleChange(event.target.value, 'trade')}
            />
            <MaterialInput
              style={{ width: '100%' }}
              label="E-mail"
              // value={email}
              // onChange={event => handleChange(event.target.value, 'trade')}
            />
            <StyledButton intern text="ADICIONAR" />
          </FormContainer>
          <Title>Regiões</Title>
          <RegionContainer>
            {mockData.region.map(({ id, name }) => (
              <Text key={id} region>
                {name}
              </Text>
            ))}
          </RegionContainer>
          <FormTitle>Comprar regiões</FormTitle>
          <StyledFormControl>
            <MaterialInputLabel htmlFor="state-simple">
              Região
            </MaterialInputLabel>
            <MaterialSelect
              inputProps={{
                name: 'state',
                id: 'state-simple',
              }}
            >
              <MaterialMenuItem value="MG">
                MG - Belo Horizonte
              </MaterialMenuItem>
              <MaterialMenuItem value="SP">SP - São Paulo</MaterialMenuItem>
              <MaterialMenuItem value="RJ">
                RJ - Rio de Janeiro
              </MaterialMenuItem>
            </MaterialSelect>
          </StyledFormControl>
          <StyledButton text="COMPRAR" />
          <Title>Histórico</Title>
          <FormTitle>Belo Horizonte</FormTitle>
          {mockData.history.map(({ id, value, balance, supplier, date }) => (
            <>
              <HistoryRowContainer key={id}>
                <HistoryTitle style={{ width: '10%' }}>Valor</HistoryTitle>
                <HistoryTitle style={{ width: '10%' }}>Saldo</HistoryTitle>
                <HistoryTitle style={{ width: '40%' }}>Fornecedor</HistoryTitle>
                <HistoryTitle style={{ width: '40%' }}>Data/Hora</HistoryTitle>
              </HistoryRowContainer>
              <HistoryRowContainer border key={id}>
                <HistoryText style={{ width: '10%' }}>{value}</HistoryText>
                <HistoryText style={{ width: '10%' }}>{balance}</HistoryText>
                <HistoryText style={{ width: '40%' }}>{supplier}</HistoryText>
                <HistoryText style={{ width: '40%' }}>{date}</HistoryText>
              </HistoryRowContainer>
            </>
          ))}
          <BalanceContainer>
            <BalanceText>Saldo:</BalanceText>
            <PointsText>30 pontos</PointsText>
          </BalanceContainer>
        </>
      )}
      <StyledButton
        text={opened ? 'FECHAR DETALHES' : 'DETALHES'}
        opened={opened}
        click={() => setOpened(!opened)}
      />
    </Wrapper>
  );
};

export default EventCard;
