import React, { Component } from 'react';
import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';

import { MaterialInput } from '../components';
import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { REGEX_EMAIL } from '../utils/regex';
import { StyledButton } from './styled';
import Axios from 'axios';
import { navigate } from 'gatsby';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 0 50px 0;
  width: 90%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      message: '',
      subject: '',
    };

    this.handleChange = (value, field) => {
      this.setState({ [field]: value });
    };

    this.sendEmail = (subject, name, email, message) => {
      Axios.post(
        'https://app.trust19.com/public/index.php/Processo/enviarEmailAPI',
        {
          from: email,
          fromName: name,
          subject: '[SITE] ' + subject,
          content: message,
        }
      ).then(data => {
        if (data.data === 1) {
          toast.error('Mensagem enviada com sucesso!');
          navigate('/agradecimento/');
          this.setState({
            name: '',
            email: '',
            message: '',
            subject: '',
          });
        } else {
          toast.error('Erro ao anviar mensagem! Tente novamente mais tarde!');
        }
      });
    };
  }
  render() {
    const { name, email, message, subject } = this.state;

    return (
      <Wrapper>
        <Card>
          <InputContainer>
            <InputLabel htmlFor="subject-simple">Assunto</InputLabel>
            <Select
              onChange={event =>
                this.handleChange(event.target.value, 'subject')
              }
              value={subject}
              inputProps={{
                name: 'subject',
                id: 'subject-simple',
              }}
            >
              <MenuItem value="Dúvida">Dúvida</MenuItem>
              <MenuItem value="Reclamação">Reclamação</MenuItem>
              <MenuItem value="Sugestão">Sugestão</MenuItem>
            </Select>
            <MaterialInput
              style={{ width: '100%' }}
              label="Seu nome"
              value={name}
              onChange={event => this.handleChange(event.target.value, 'name')}
            />
            <MaterialInput
              style={{ width: '100%' }}
              label="Seu melhor email"
              value={email}
              onChange={event => this.handleChange(event.target.value, 'email')}
            />
            <MaterialInput
              label="Sua mensagem"
              style={{ width: '100%' }}
              multiline
              rows="6"
              value={message}
              onChange={event =>
                this.handleChange(event.target.value, 'message')
              }
            />
            <StyledButton
              text="ENVIAR"
              click={() => {
                if (!subject || !name || !email || !message) {
                  toast.error('Todos os campos são obrigatórios!');
                  return false;
                }

                if (!REGEX_EMAIL.test(email)) {
                  toast.error('Digite um e-mail válido!');
                  return false;
                } else {
                  this.sendEmail(subject, name, email, message);
                }
              }}
            />
          </InputContainer>
        </Card>
      </Wrapper>
    );
  }
}

export default ContactForm;
